
import { Options, mixins } from 'vue-class-component'
import CLoading from '@/components/common/ui/CLoading.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import { Watch } from 'vue-property-decorator'
import { ACTION_ANSWER_REPORT, ACTION_FORM } from '@/store/actions'
import DateRangeSelectorV2 from '@/components/common/ui/DateRangeSelectorV2.vue'
import { IDateRangePicker, IFormReport, IFormReportCSV } from '@/utils/types'
import { DatetimeUtils } from '@/utils/datetime'
import JsonCSV from '@/components/common/ui/JsonCSV.vue'
import { CSVHeader } from '@/utils/csv'
import TagContent from '@/components/common/ui/TagContent.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: {
    TagContent,
    DateRangeSelectorV2,
    TestModeSelector,
    CLoading,
    'download-csv': JsonCSV,
  },
})
export default class FormReport extends mixins(BaseFormMixin) {
  loading = true
  isTestMode = false
  dateRange: IDateRangePicker = {
    startDate: '',
    endDate: '',
  }

  selectedFormId = ''

  reports: IFormReport[] = []

  csvHeader: CSVHeader | null = null
  jsonData = [{}]

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get forms() {
    return this.$store.getters.forms
  }

  @Watch('selectedAppId', { immediate: true })
  async updateApi() {
    if (!this.selectedAppId) {
      return
    }

    this.dateRange = DatetimeUtils.getDateRange('last30Days')

    await this.loadData()
  }

  @Watch('isTestMode')
  @Watch('selectedFormId')
  handleChangeTargetSetting() {
    this.loadData()
  }

  async loadData() {
    this.loading = true

    this.reports = await this.$store.dispatch(ACTION_ANSWER_REPORT.LOAD_USER_DETAILS, {
      app_id: this.selectedAppId,
      from_date: DatetimeUtils.formatDate(this.dateRange.startDate),
      to_date: DatetimeUtils.formatDate(this.dateRange.endDate),
      form_id: this.selectedFormId,
      is_test_mode: this.isTestMode,
    })

    this.loading = false
  }

  changeDateRange(value) {
    this.dateRange = value

    this.loadData()
  }

  exportFinished() {
    this.loading = false
  }

  onExportReport() {
    if (this.reports.length === 0) {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.no_data_available'),
        persistent: true,
      })
      return
    }

    this.loading = true
    // Download CSV header
    this.csvHeader = new CSVHeader({
      updated_at: this.$t('label.updated_at'),
      display_name: this.$t('label.audience_user.display_name'),
      name_kanji: this.$t('label.common.form.form_field_type_name_kanji'),
      name_hira: this.$t('label.common.form.form_field_type_name_hira'),
      name_kana: this.$t('label.common.form.form_field_type_name_kana'),
      phone_number: this.$t('label.common.form.form_field_type_phone_number_dash'),
      phone_number_no_dash: this.$t('label.common.form.form_field_type_phone_number_no_dash'),
      address: this.$t('label.common.form.form_field_type_address'),
      postcode_dash: this.$t('label.common.form.form_field_type_postcode_dash'),
      postcode_no_dash: this.$t('label.common.form.form_field_type_postcode_no_dash'),
      email: this.$t('label.common.form.form_field_type_email'),
      freetext: this.$t('label.common.form.form_field_type_freetext'),
    })

    const data: IFormReportCSV[] = []
    this.reports.forEach((item) => {
      data.push({
        updated_at: item.updated_at,
        display_name: item.display_name,
        name_kanji: item.details?.name_kanji,
        name_hira: item.details?.name_hira,
        name_kana: item.details?.name_kana,
        phone_number: item.details?.phone_number,
        phone_number_no_dash: item.details?.phone_number_no_dash,
        address: item.details?.address,
        postcode_dash: item.details?.postcode_dash,
        postcode_no_dash: item.details?.postcode_no_dash,
        email: item.details?.email,
        freetext: item.details?.freetext,
      })
    })
    this.jsonData = data
    console.log(this.jsonData, 'this.jsonData')

    this.$nextTick(() => {
      this.$refs.downloadCSV.generate()
    })
    this.loading = false
  }

  async created() {
    await this.$store.dispatch(ACTION_FORM.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })
  }
}
